/**
 * Filters
 * @module components/Filters
 */

import React from 'react';
import PropTypes from 'prop-types';
import './Filters.scss';

import 'styles/datepicker.scss';

import { Select, Checkbox, Form, Button, Datepicker } from 'components/Form';

const renderFieldset = (options, name, title) => {
	return (
		<fieldset className="form__fieldset">
			<legend className="form__subheading">{title}</legend>
			{options.map((opt, i) => {
				return (
					<div className="form__fieldset__checkbox" key={i}>
						<Checkbox
							type="checkbox"
							name={name}
							value={opt.id.toString()}
							id={`${name}-${opt.id}`}
							autoSubmit={true}
						/>
						<label
							className="form__label form__label--lightgrey"
							htmlFor={`${name}-${opt.id}`}
						>
							{opt.name}
						</label>
					</div>
				);
			})}
		</fieldset>
	);
};

const Filters = React.memo(props => {
	const { metadata, onSubmit } = props;

	if (Object.keys(metadata).length < 1) {
		return;
	}

	const seasons = metadata.seasons
		? [{ name: 'Alla', id: 'all' }, ...metadata.seasons]
		: [];

	return (
		<Form
			onSubmit={onSubmit}
			initialValues={{ organizationId: '0' }}
			className="filters"
			onReset={() => props.onReset()}
		>
			<div className="form__section">
				<div className="form__item  form__item--SelectContainer">
					<label htmlFor="season" className="form__subheading">
						Välj säsong
					</label>
					<div className="form__item--Select">
						<Select
							id="season"
							name="season"
							options={seasons}
							autoSubmit={true}
						/>
					</div>

					<label htmlFor="organizationId" className="form__subheading">
						Välj förening
					</label>
					<div className="form__item--Select">
						<Select
							id="organizationId"
							name="organizationId"
							options={[
								{ name: 'Alla', id: 0, disabled: 'disabled' },
								...metadata.organizations,
							]}
							autoSubmit={true}
						/>
					</div>

					<div className="filters__time">
						<div className="form__item form__item--Text">
							<label htmlFor="dateFrom" className="form__label">
								Från
							</label>
							<Datepicker
								placeholder="Välj datum"
								name="dateFrom"
								autoSubmit={true}
							/>
						</div>
						<div className="form__item form__item--Text">
							<label htmlFor="dateTo" className="form__label">
								Till
							</label>
							<Datepicker
								placeholderText="Välj datum"
								name="dateTo"
								autoSubmit={true}
							/>
						</div>
					</div>
				</div>

				{renderFieldset(metadata['leagueTypes'], 'leagueTypes', 'Liga')}

				{renderFieldset(metadata['reportTypes'], 'reportType', 'Tillfälle')}

				{renderFieldset(
					metadata['reportIncidents'],
					'reportIncidents',
					'Status skada'
				)}

				{renderFieldset(metadata['reportEvents'], 'reportEvent', 'Händelse')}
			</div>

			<div className="form__flex">
				<Button type="reset" className="form__button form__button--Submit">
					Rensa
				</Button>
			</div>
		</Form>
	);
});

Filters.propTypes = {
	metadata: PropTypes.object,
	onSubmit: PropTypes.func,
};

export default Filters;
