/* eslint-disable jsx-a11y/no-access-key */
/**
 * PageHeader
 * @module components/PageHeader
 */

import React from 'react';
import PropTypes from 'prop-types';
import './PageHeader.scss';
import { Link } from 'react-router-dom';
import { TOKEN_NAME } from 'common/config';

import Layout from 'components/Layout';
import BrandHeader from 'components/BrandHeader';
import Brain from 'components/Brain';
import Icon from 'components/Icon';
import MainMenu from 'components/MainMenu';

const PageHeader = React.memo(({ isNotLoggedIn }) => {
	return (
		<header className="page-header">
			<a
				href="#main-content"
				accessKey="s"
				className="is-visuallyhidden focusable page-header__skip"
			>
				Hoppa till huvudinnehållet
			</a>
			<Layout>
				<div className="page-header__content">
					<div className="page-header__logo">
						<Link to="/">
							<img
								src="/assets/images/logotype.ico"
								alt="Svenska Ishockeyförbundet logotyp"
							/>
						</Link>
					</div>
					<BrandHeader subtitle="Rapportering hjärnskakning" />
					{!isNotLoggedIn && <MainMenu />}
					{!isNotLoggedIn && (
						<div className="page-header__button">
							<Link
								to="/logga-in"
								onClick={() => localStorage.removeItem(TOKEN_NAME)}
							>
								<span className="page-header__button__text">Logga ut</span>
								<Icon icon="icon-log-out" color="white" />
							</Link>
						</div>
					)}
					<Brain />
				</div>
			</Layout>
		</header>
	);
});

PageHeader.propTypes = {
	isNotLoggedIn: PropTypes.bool,
};

export default PageHeader;
