/**
 * PageFooter
 * @module components/PageFooter
 */

import React from 'react';
import './PageFooter.scss';

const PageFooter = props => {
	return <div className="page-footer" />;
};

export default PageFooter;
