// Users
export const USER_FETCH = 'USER_FETCH';
export const USER_SORT = 'USER_SORT';
export const USER_SEARCH = 'USER_SEARCH';
export const USER_CREATE = 'USER_CREATE';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_DELETE = 'USER_DELETE';
export const USER_SELECT = 'USER_SELECT';
export const USER_ERROR = 'USER_ERROR';

// Reports
export const REPORT_FETCH = 'REPORT_FETCH';
export const REPORT_SORT = 'REPORT_SORT';
export const REPORT_UPDATE = 'REPORT_UPDATE';
export const REPORT_FILTER = 'REPORT_FILTER';
export const REPORT_DELETE = 'REPORT_DELETE';
export const REPORT_SELECT = 'REPORT_SELECT';
export const REPORT_ERROR = 'REPORT_ERROR';

// Metadata
export const METADATA_FETCH = 'METADATA_FETCH';
export const METADATA_SELECT_ORGANIZATION = 'METADATA_SELECT_ORGANIZATION';
export const METADATA_ERROR = 'METADATA_ERROR';
export const METADATA_SELECT_LEAGUE = 'METADATA_SELECT_LEAGUE';

// Organizations
export const ORGANIZATION_FETCH = 'ORGANIZATION_FETCH';
export const ORGANIZATION_SORT = 'ORGANIZATION_SORT';
export const ORGANIZATION_SEARCH = 'ORGANIZATION_SEARCH';
export const ORGANIZATION_CREATE = 'ORGANIZATION_CREATE';
export const ORGANIZATION_UPDATE = 'ORGANIZATION_UPDATE';
export const ORGANIZATION_DELETE = 'ORGANIZATION_DELETE';
export const ORGANIZATION_SELECT = 'ORGANIZATION_SELECT';
export const ORGANIZATION_ERROR = 'ORGANIZATION_ERROR';
