/**
 * RadioButton
 * @module components/RadioButton
 */

import React from 'react';
import PropTypes from 'prop-types';

const RadioButton = props => {
	const { id, name, defaultValue, onFieldChange, autoSubmit, value } = props;

	return (
		<input
			type="radio"
			id={id}
			name={name}
			value={value}
			defaultChecked={value === defaultValue}
			onChange={e =>
				onFieldChange({
					name,
					value: e.target.value,
					type: 'RadioButton',
					autoSubmit,
				})
			}
		/>
	);
};

RadioButton.propTypes = {
	type: PropTypes.string,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	name: PropTypes.string,
	placeholder: PropTypes.string,
	value: PropTypes.string,
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	autoSubmit: PropTypes.bool,
	onFieldChange: PropTypes.func,
};

export default RadioButton;
