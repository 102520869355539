/**
 * Root reducer.
 * @module reducers/rootReducer
 */

import { combineReducers } from 'redux';
import userReducer from 'reducers/user.reducer';
import metadataReducer from 'reducers/metadata.reducer';
import reportReducer from 'reducers/report.reducer';
import organizationReducer from 'reducers/organization.reducer';

const rootReducer = combineReducers({
	user: userReducer,
	metadata: metadataReducer,
	report: reportReducer,
	organization: organizationReducer,
});

export default rootReducer;
