import fetch from 'unfetch';
import { API_URL, TOKEN_NAME } from 'common/config';
import {
	METADATA_FETCH,
	METADATA_SELECT_ORGANIZATION,
	METADATA_SELECT_LEAGUE,
	METADATA_ERROR,
} from 'actions/types';

/**
 * Action for fetching metadata
 */
export const fetchMetadata = () => {
	const token = JSON.parse(localStorage.getItem(TOKEN_NAME)).token;
	return dispatch => {
		return fetch(`${API_URL}/Report/metadata`, {
			cache: 'no-cache',
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json-patch+json',
			},
			method: 'GET',
			credentials: 'same-origin',
			redirect: 'follow',
			referrer: 'no-referrer',
		})
			.then(response => response.json())
			.then(response => {
				if (response) {
					dispatch({
						type: METADATA_FETCH,
						payload: response,
					});
				} else {
					dispatch(metadataError("Couldn't fetch metadata."));
				}
			})
			.catch(() => {
				dispatch(metadataError("Couldn't fetch metadata."));
			});
	};
};

/**
 * Action for selecting an organisation
 * @param {String} orgId - The selected organization
 * @param {Array} organizations - All organizations
 */
export const selectOrganization = (orgId, organizations) => {
	const selectedOrg = organizations.filter(org => org.id === Number(orgId))[0];

	return dispatch => {
		dispatch({
			type: METADATA_SELECT_ORGANIZATION,
			payload: selectedOrg.id,
		});
	};
};

/**
 * Action for selecting an league
 * @param {String} leagueId - The selected league
 * @param {Array} leagueTypes - All leagues
 */
export const selectLeague = (leagueId, leagueTypes) => {
	const selectedLeague = leagueTypes.filter(
		league => league.id === Number(leagueId)
	)[0];

	return dispatch => {
		dispatch({
			type: METADATA_SELECT_LEAGUE,
			payload: selectedLeague.id,
		});
	};
};

/**
 * Action for dispatching a metadata error
 */
export function metadataError(error) {
	return {
		type: METADATA_ERROR,
		payload: error,
	};
}
