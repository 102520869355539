/**
 * ReportListItem
 * @module pages/ReportListItem
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const ReportListItem = ({ report, metadata }) => {
	if (Object.keys(metadata.metadata).length === 0) {
		return null;
	}

	return (
		<tr
			id={`id-${report.id}`}
			onClick={() => document.querySelector(`#id-${report.id} a`).click()}
		>
			<td>
				<Link
					to={{
						pathname: `rapporter/${report.id}`,
						state: { selectedUser: report, openModal: true },
					}}
				>
					{report.id}
				</Link>
			</td>
			<td>{report.leagueType.name}</td>
			<td>{report.organization.name}</td>
			<td>{report.position.name}</td>
			<td>{report.incident.name}</td>
			<td>{report.type.name}</td>
			<td>{report.date.slice(0, 10)}</td>
			<td>{report.event.name}</td>
		</tr>
	);
};

const mapStateToProps = state => {
	return {
		metadata: state.metadata,
	};
};

export default connect(
	mapStateToProps,
	{}
)(ReportListItem);
