/**
 * Organizations reducer
 * @module reducers/organization.reducer
 */

import {
	ORGANIZATION_FETCH,
	ORGANIZATION_SORT,
	ORGANIZATION_SEARCH,
	ORGANIZATION_CREATE,
	ORGANIZATION_UPDATE,
	ORGANIZATION_DELETE,
	ORGANIZATION_SELECT,
	ORGANIZATION_ERROR,
} from 'actions/types';

const INITIAL_STATE = { organizations: {}, selected: null, error: null };

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		case ORGANIZATION_FETCH:
		case ORGANIZATION_SEARCH:
		case ORGANIZATION_SORT: {
			return { ...state, organizations: action.payload };
		}
		case ORGANIZATION_CREATE: {
			const updatedOrganizations = state.organizations.items.slice();
			updatedOrganizations.push(action.payload);

			return {
				...state,
				organizations: {
					items: updatedOrganizations,
					page: state.organizations.page,
					limit: state.organizations.limit,
					pageCount: state.organizations.pageCount,
				},
			};
		}
		case ORGANIZATION_UPDATE: {
			const updatedOrganizations = state.organizations.items.filter(
				organization => organization.id !== action.payload.id
			);

			updatedOrganizations.push(action.payload);

			return {
				...state,
				organizations: {
					items: updatedOrganizations,
					page: state.organizations.page,
					limit: state.organizations.limit,
					pageCount: state.organizations.pageCount,
				},
			};
		}
		case ORGANIZATION_DELETE: {
			const updatedOrganizations = state.organizations.items.filter(
				organization => organization.id !== action.payload
			);

			return {
				...state,
				organizations: {
					items: updatedOrganizations,
					page: state.organizations.page,
					limit: state.organizations.limit,
					pageCount: state.organizations.pageCount,
				},
			};
		}
		case ORGANIZATION_SELECT: {
			return { ...state, selected: action.payload };
		}
		case ORGANIZATION_ERROR: {
			return { ...state, error: action.payload };
		}
		default:
			return state;
	}
}
