/**
 * Table
 * @module components/Table
 */

import React from 'react';
import PropTypes from 'prop-types';
import './Table.scss';

const Table = ({ children, caption, scrollable }) => (
	<div className="table__wrapper">
		<table className={scrollable ? 'table table--scrollable' : 'table'}>
			{caption && <caption className="table__caption">{caption}</caption>}
			{children}
		</table>
	</div>
);

Table.propTypes = {
	caption: PropTypes.string,
	scrollable: PropTypes.bool,
};

export default Table;
