import fetch from 'unfetch';
import { API_URL, TOKEN_NAME } from 'common/config';
import {
	REPORT_FETCH,
	REPORT_SORT,
	REPORT_UPDATE,
	REPORT_DELETE,
	REPORT_SELECT,
	REPORT_ERROR,
	REPORT_FILTER,
} from 'actions/types';

/**
 * Action for fetching all reports
 * @param {String} url - If we have a url, we're requesting a specific page
 */
export const fetchReports = url => {
	const token = JSON.parse(localStorage.getItem(TOKEN_NAME)).token;
	const requestUrl = url ? `${API_URL}${url}` : `${API_URL}/Report`;

	return dispatch => {
		return fetch(requestUrl, {
			cache: 'no-cache',
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json-patch+json',
			},
			method: 'GET',
			credentials: 'same-origin',
			redirect: 'follow',
			referrer: 'no-referrer',
		})
			.then(response => response.json())
			.then(response => {
				if (response.items) {
					dispatch({
						type: REPORT_FETCH,
						payload: response,
					});
				} else {
					dispatch(reportsError("Couldn't fetch reports."));
				}
			})
			.catch(() => {
				dispatch(reportsError("Couldn't fetch reports."));
			});
	};
};

/**
 * Action for sorting reports
 * @param {String} orderby - Field to order the list by
 * @param {Boolean} order - true = ascending, false = descending
 * @param {String} query - query string with active filters
 */
export const sortReports = (orderby, order, query) => {
	const token = JSON.parse(localStorage.getItem(TOKEN_NAME)).token;
	const filters = query ? `&${query}` : '';

	return dispatch => {
		return fetch(
			`${API_URL}/Report?orderby=${orderby}&order=${order}${filters}`,
			{
				cache: 'no-cache',
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json-patch+json',
				},
				method: 'GET',
				credentials: 'same-origin',
				redirect: 'follow',
				referrer: 'no-referrer',
			}
		)
			.then(response => response.json())
			.then(response => {
				dispatch({
					type: REPORT_SORT,
					payload: response,
				});
			})
			.catch(() => {
				dispatch(reportsError(`Couldn't sort reports by ${orderby}.`));
			});
	};
};

/**
 * Action for filtering reports
 * @param {String} query - query string with active filters
 */
export const filterReports = query => {
	const token = JSON.parse(localStorage.getItem(TOKEN_NAME)).token;

	return dispatch => {
		return fetch(`${API_URL}/Report${query}`, {
			cache: 'no-cache',
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json-patch+json',
			},
			method: 'GET',
			credentials: 'same-origin',
			redirect: 'follow',
			referrer: 'no-referrer',
		})
			.then(response => response.json())
			.then(response => {
				dispatch({
					type: REPORT_FILTER,
					payload: response,
				});
			})
			.catch(() => {
				dispatch(reportsError(`Couldn't filter reports by query ${query}.`));
			});
	};
};

/**
 * Action for selecting a report
 * @param {Object} report - The selected report
 */
export const selectReport = report => {
	return dispatch => {
		dispatch({
			type: REPORT_SELECT,
			payload: report,
		});
	};
};

/**
 * Action for updating a report
 * @param {Object} report - The report to update
 */
export const updateReport = (report, id, history) => {
	const token = JSON.parse(localStorage.getItem(TOKEN_NAME)).token;

	return dispatch => {
		return fetch(`${API_URL}/Report/${id}`, {
			body: JSON.stringify(report),
			cache: 'no-cache',
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json-patch+json',
			},
			method: 'PATCH',
			credentials: 'same-origin',
			redirect: 'follow',
			referrer: 'no-referrer',
		})
			.then(response => response.json())
			.then(response => {
				if (response.id) {
					dispatch({
						type: REPORT_UPDATE,
						payload: response,
					});
					history.push('/rapporter');
				} else {
					dispatch(reportsError(`Couldn't update report with id ${id}.`));
				}
			})
			.catch(() => {
				dispatch(reportsError(`Couldn't update report with id ${id}.`));
			});
	};
};

/**
 * Action for deleting a report
 * @param {String} reportId - Id of report to delete
 */
export const deleteReport = reportId => {
	const token = JSON.parse(localStorage.getItem(TOKEN_NAME)).token;

	return dispatch => {
		return fetch(`${API_URL}/Report/${reportId}`, {
			cache: 'no-cache',
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json-patch+json',
			},
			method: 'DELETE',
			credentials: 'same-origin',
			redirect: 'follow',
			referrer: 'no-referrer',
		})
			.then(response => {
				if (response.status === 200) {
					dispatch({
						type: REPORT_DELETE,
						payload: reportId,
					});
				} else {
					dispatch(reportsError(`Couldn't delete report with id ${reportId}.`));
				}
			})
			.catch(() => {
				dispatch(reportsError(`Couldn't delete report with id ${reportId}.`));
			});
	};
};

/**
 * Action for exporting reports
 * @param {String} type - xlsx/csv, format to export in
 */
export const exportReports = type => {
	const token = JSON.parse(localStorage.getItem(TOKEN_NAME)).token;

	return dispatch => {
		const xhr = new XMLHttpRequest();
		xhr.open('GET', `${API_URL}/Report/Rapport.${type}`, true);
		xhr.responseType = 'blob';
		xhr.setRequestHeader('Authorization', `Bearer ${token}`);

		xhr.onload = () => {
			if (xhr.status === 200) {
				const blob = xhr.response;

				if (navigator.appVersion.toString().indexOf('.NET') > 0) {
					window.navigator.msSaveBlob(blob, `Rapport.${type}`);
				} else {
					const linkElem = document.createElement('a');
					const blobUrl = window.URL.createObjectURL(
						new Blob([blob], { type: blob.type })
					);
					document.body.appendChild(linkElem);
					linkElem.style.cssText = 'display: none;';
					linkElem.href = blobUrl;
					linkElem.download = `Rapport.${type}`;
					linkElem.click();

					document.body.removeChild(linkElem);
				}
			} else {
				dispatch(reportsError(`Couldn't export reports as ${type}.`));
			}
		};

		xhr.send();
	};
};

/**
 * Action for dispatching a reports error
 */
export function reportsError(error) {
	return {
		type: REPORT_ERROR,
		payload: error,
	};
}
