/**
 * Reports reducer
 * @module reducers/report.reducer
 */

import {
	REPORT_FETCH,
	REPORT_SORT,
	REPORT_UPDATE,
	REPORT_DELETE,
	REPORT_SELECT,
	REPORT_ERROR,
	REPORT_FILTER,
} from 'actions/types';

const INITIAL_STATE = { reports: {}, selected: null, error: null };

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		case REPORT_FETCH:
		case REPORT_FILTER:
		case REPORT_SORT: {
			return { ...state, reports: action.payload };
		}
		case REPORT_UPDATE: {
			const updatedReports = state.reports.items.filter(
				report => report.id !== action.payload.id
			);

			updatedReports.push(action.payload);

			return {
				...state,
				reports: {
					items: updatedReports,
					page: state.reports.page,
					limit: state.reports.limit,
					pageCount: state.reports.pageCount,
				},
			};
		}
		case REPORT_DELETE: {
			const updatedReports = state.reports.items.filter(
				report => report.id !== action.payload
			);

			return {
				...state,
				reports: {
					items: updatedReports,
					page: state.reports.page,
					limit: state.reports.limit,
					pageCount: state.reports.pageCount,
				},
			};
		}
		case REPORT_SELECT: {
			return { ...state, selected: action.payload };
		}
		case REPORT_ERROR: {
			return { ...state, error: action.payload };
		}
		default:
			return state;
	}
}
