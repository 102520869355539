/**
 * StandardLayout
 * @module layouts/StandardLayout
 */

import React from 'react';

import PageHeader from 'components/PageHeader';
import PageFooter from 'components/PageFooter';

const StandardLayout = ({ children, location }) => {
	return (
		<>
			<PageHeader
				isNotLoggedIn={location && location.pathname === '/logga-in'}
			/>
			{children}
			<PageFooter />
		</>
	);
};

export default StandardLayout;
