import { TOKEN_NAME } from 'common/config';

/**
 * Polyfill for forEach functions on objects to work on IE/Edge
 */
export const polyfillForEach = () => {
	if (
		typeof NodeList !== 'undefined' &&
		typeof NodeList.prototype.forEach !== 'function'
	) {
		NodeList.prototype.forEach = Array.prototype.forEach;
	}
};

/**
 * Formats a date as DD Month YYYY
 * @param {Date} date - A date object
 */
export const getFormattedDate = date => {
	if (date) {
		const months = [
			'Januari',
			'Februari',
			'Mars',
			'April',
			'Maj',
			'Juni',
			'Juli',
			'Augusti',
			'September',
			'Oktober',
			'November',
			'December',
		];
		const monthIndex = date.getMonth() - 1;

		return `${date.getDate()} ${months[monthIndex]} ${date.getFullYear()}`;
	} else {
		return '-';
	}
};

/**
 * Checks if token is still valid
 * @returns {Boolean} - true if still valid
 */
export const checkTokenValidity = () => {
	const token = JSON.parse(localStorage.getItem(TOKEN_NAME));
	if (token) {
		const tokenExpires = Date.parse(token.expires);
		return tokenExpires > Date.now();
	} else {
		return false;
	}
};

/**
 * Returns a query strings of the provided filters
 * @param {Object} filters - Object with currently selected filters
 * @returns {String}
 */
export const getQueryString = filters => {
	let query = '';

	Object.keys(filters).forEach(filterName => {
		const filterValue = filters[filterName];

		if (
			(Array.isArray(filterValue) && filterValue.length === 0) ||
			(filterName === 'organizationId' && filterValue === '0') ||
			filterValue === null ||
			(!(filterValue instanceof Date) &&
				!Array.isArray(filterValue) &&
				filterValue.toLowerCase() === 'all')
		) {
			return;
		}

		query += query.length > 0 ? '&' : '?';

		if (filterValue instanceof Date) {
			const date = getDate(filterValue);
			query = `${query}${filterName}=${date}`;
		} else if (!Array.isArray(filterValue)) {
			query = `${query}${filterName}=${filterValue}`;
		} else {
			for (let index = 0; index < filterValue.length; index++) {
				const element = filterValue[index];
				const and = index > 0 ? '&' : '';
				query += `${and}${filterName}=${element}`;
			}
		}
	});

	return query;
};

/**
 * Converts a date object into a string in the format YYYY-MM-DD
 * @param {Date} fullDate - A date object
 * @returns {String} - The formatted date string (YYYY-MM-DD)
 */
export const getDate = fullDate => {
	const year = fullDate.getFullYear();
	let date = fullDate.getDate();
	let month = fullDate.getMonth() + 1;

	date = date < 10 ? `0${date}` : date;
	month = month < 10 ? `0${month}` : month;

	return `${year}-${month}-${date}`;
};
