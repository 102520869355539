/**
 * DatePicker
 * @module components/DatePicker
 * @version 1.0
 */

import React, { Component } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import sv from 'date-fns/locale/sv';
import PropTypes from 'prop-types';

registerLocale('sv', sv);

class Datepicker extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: props.defaultValue || '',
		};

		this.fieldRef = React.createRef();
	}

	componentDidUpdate() {
		if (this.props.setFocus) {
			this.fieldRef.current.focus();
		}
	}

	/**
	 * Callback method for onChange.
	 */
	onChange = date => {
		const value = date;
		const { name, onFieldChange, autoSubmit } = this.props;

		this.setState({ value });
		onFieldChange({ name, value, autoSubmit, type: 'Field' });
	};

	/**
	 * The render method
	 */
	render() {
		const {
			className,
			type,
			id,
			name,
			placeholder,
			required,
			pattern,
			setFocus,
			disabled,
			onFieldChange,
			resetForm,
			defaultValue,
			description,
			...props
		} = this.props;
		const { value } = this.state;

		return (
			<DatePicker
				selected={value}
				onChange={this.onChange}
				locale="sv"
				dateFormat="yyyy-MM-dd"
				maxDate={new Date()}
				className={className || ''}
				id={id}
				title={description}
				name={name}
				required={required}
				disabled={disabled}
				placeholderText={placeholder || ''}
				pattern={pattern}
				ref={this.fieldRef}
				isClearable={value ? true : false}
				{...props}
			/>
		);
	}
}

/** Prop types **/
Datepicker.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string,
	id: PropTypes.string,
	description: PropTypes.string,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	pattern: PropTypes.string,
	required: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	defaultValue: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.any,
	]),
	autoSubmit: PropTypes.bool,
	setFocus: PropTypes.bool,
	onFieldChange: PropTypes.func,
	disabled: PropTypes.bool,
};

export default Datepicker;
