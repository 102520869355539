/**
 * Users reducer
 * @module reducers/user.reducer
 */

import {
	USER_FETCH,
	USER_SORT,
	USER_SEARCH,
	USER_CREATE,
	USER_UPDATE,
	USER_DELETE,
	USER_SELECT,
	USER_ERROR,
} from 'actions/types';

const INITIAL_STATE = { users: {}, selected: null, error: null };

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		case USER_FETCH:
		case USER_SEARCH:
		case USER_SORT: {
			return { ...state, users: action.payload };
		}
		case USER_CREATE: {
			const updatedUsers = state.users.items.slice();
			updatedUsers.push(action.payload);

			return {
				...state,
				users: {
					items: updatedUsers,
					page: state.users.page,
					limit: state.users.limit,
					pageCount: state.users.pageCount,
				},
			};
		}
		case USER_UPDATE: {
			const updatedUsers = state.users.items.filter(
				user => user.id !== action.payload.id
			);

			updatedUsers.push(action.payload);

			return {
				...state,
				users: {
					items: updatedUsers,
					page: state.users.page,
					limit: state.users.limit,
					pageCount: state.users.pageCount,
				},
			};
		}
		case USER_DELETE: {
			const updatedUsers = state.users.items.filter(
				user => user.id !== action.payload
			);

			return {
				...state,
				users: {
					items: updatedUsers,
					page: state.users.page,
					limit: state.users.limit,
					pageCount: state.users.pageCount,
				},
			};
		}
		case USER_SELECT: {
			return { ...state, selected: action.payload };
		}
		case USER_ERROR: {
			return { ...state, error: action.payload };
		}
		default:
			return state;
	}
}
