/**
 * Users reducer
 * @module reducers/users.reducer
 */

import {
	METADATA_FETCH,
	METADATA_SELECT_ORGANIZATION,
	METADATA_ERROR,
} from 'actions/types';

const INITIAL_STATE = {
	metadata: {},
	selected: null,
	error: null,
};

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		case METADATA_FETCH: {
			return { ...state, metadata: action.payload };
		}
		case METADATA_SELECT_ORGANIZATION: {
			return { ...state, selected: action.payload };
		}
		case METADATA_ERROR: {
			return { ...state, error: action.payload };
		}
		default:
			return state;
	}
}
