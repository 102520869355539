/**
 * EditUserModal
 * @module pages/EditUserModal
 */

import React, { useState } from 'react';
import Modal from 'components/Modal';
import { connect } from 'react-redux';

import { Form, Field, Select, Button } from 'components/Form';
import { deleteUser, updateUser, createUser } from 'actions/user.actions';

const EditUserModal = ({ history, user, metadata, ...props }) => {
	const [passwordError, setPasswordError] = useState(false);

	const handleSubmit = values => {
		if (values.password && values.password.length < 6) {
			setPasswordError(true);
		} else if (user.selected) {
			props.updateUser(values, history);
		} else {
			props.createUser(values, history);
		}
	};

	const initialOrganization = metadata.selected
		? metadata.selected
		: user.selected && user.selected.organization
		? user.selected.organization.id
		: '14';

	return (
		<Modal
			onExit={() => history.push('/anvandare')}
			titleText="Skapa en ny användare eller redigera befintlig användare"
			closeLink="/anvandare"
			heading="Användare"
		>
			<Form
				initialValues={{
					...user.selected,
					organizationId: initialOrganization,
				}}
				onSubmit={handleSubmit}
			>
				<div className="form__item form__item--Text">
					<label htmlFor="lastName" className="form__label">
						Efternamn
					</label>
					<Field name="lastName" id="lastName" required />
				</div>
				<div className="form__item form__item--Text">
					<label htmlFor="firstName" className="form__label">
						Förnamn
					</label>
					<Field name="firstName" id="firstName" required />
				</div>
				{(!user.selected ||
					(user.selected && user.selected.organization !== null)) && (
					<div className="form__item form__item--Select">
						<label htmlFor="organizationId" className="form__label">
							Förening
						</label>
						<Select
							id="organizationId"
							name="organizationId"
							options={metadata.metadata.organizations || []}
						/>
					</div>
				)}
				<div className="form__item form__item--Text">
					<label htmlFor="email" className="form__label">
						E-post
					</label>
					<Field name="email" type="email" id="email" required />
				</div>
				<div className="form__item form__item--Text form__item--small">
					<label htmlFor="phone" className="form__label">
						Telefon
					</label>
					<Field name="phone" type="number" id="phone" />
				</div>
				<div className="form__item form__item--Text form__item--small">
					<label htmlFor="password" className="form__label">
						Lösenord
					</label>
					<Field name="password" id="password" />
					{passwordError && (
						<p className="userspage__error">
							Lösenordet måste vara minst 6 tecken.
						</p>
					)}
				</div>
				<div className="form__item">
					{user.error && <p className="userspage__error">{user.error}</p>}

					<Button type="submit" className="form__button form__button--Submit">
						Spara
					</Button>
					<Button
						type="button"
						className="form__button form__button--Submit form__button--Delete"
						onClick={e => {
							e.preventDefault();
							props.deleteUser(user.selected.id);
							history.push('/anvandare');
						}}
					>
						Ta bort
					</Button>
				</div>
			</Form>
		</Modal>
	);
};

const mapStateToProps = state => {
	return {
		user: state.user,
		metadata: state.metadata,
	};
};

export default connect(
	mapStateToProps,
	{ deleteUser, updateUser, createUser }
)(EditUserModal);
