import React, { useState, useEffect } from 'react';
import 'styles/global.scss';
import { polyfillForEach, checkTokenValidity } from 'common/helpers';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { fetchReports } from 'actions/report.actions';
import { fetchUsers } from 'actions/user.actions';
import { fetchMetadata } from 'actions/metadata.actions';
import { fetchOrganizations } from 'actions/organization.actions';

import LoginPage from 'pages/LoginPage';
import UsersPage from 'pages/UsersPage';
import ReportsPage from 'pages/ReportsPage';
import ErrorPage from 'pages/ErrorPage';
import OrganizationPage from 'pages/OrganizationPage';

polyfillForEach();

/**
 * Handling of private routes
 */
const PrivateRoute = ({ component: Component, openModal, ...rest }) => {
	return (
		<Route
			{...rest}
			render={props =>
				checkTokenValidity() ? (
					<Component {...props} openModal={openModal} />
				) : (
					<Redirect
						to={{
							pathname: '/logga-in',
							state: { from: props.location },
						}}
					/>
				)
			}
		/>
	);
};

/**
 * App - start of application
 */
const App = ({
	fetchMetadata,
	fetchReports,
	fetchUsers,
	fetchOrganizations,
	...props
}) => {
	const [stateInitialized, setStateInitialized] = useState(false);

	useEffect(() => {
		if (checkTokenValidity() && !stateInitialized) {
			fetchUsers();
			fetchMetadata();
			fetchReports();
			fetchOrganizations();
			setStateInitialized(true);
		}
	});

	return (
		<Switch>
			<PrivateRoute exact path="/" component={ReportsPage} />
			<Route path="/logga-in" component={LoginPage} />
			<PrivateRoute exact path="/anvandare" component={UsersPage} />
			<PrivateRoute
				path="/anvandare/:id"
				component={UsersPage}
				openModal={true}
			/>
			<PrivateRoute exact path="/rapporter" component={ReportsPage} />
			<PrivateRoute
				path="/rapporter/:id"
				component={ReportsPage}
				openModal={true}
			/>
			<PrivateRoute exact path="/forening" component={OrganizationPage} />
			<PrivateRoute
				path="/forening/:id"
				component={OrganizationPage}
				openModal={true}
			/>
			<Route component={ErrorPage} />
		</Switch>
	);
};

const mapStateToProps = state => {
	return {
		report: state.report,
		metadata: state.metadata,
		user: state.user,
		organization: state.organization,
	};
};

export default connect(
	mapStateToProps,
	{ fetchUsers, fetchReports, fetchMetadata, fetchOrganizations }
)(App);
