/**
 * EditReportModal
 * @module pages/EditReportModal
 */

import React, { useState } from 'react';
import Modal from 'components/Modal';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import sv from 'date-fns/locale/sv';
import 'styles/datepicker.scss';

import { Form, TextArea, Select, Button } from 'components/Form';
import { deleteReport, updateReport } from 'actions/report.actions';

const EditReportModal = ({ history, report, metadata, ...props }) => {
	const {
		organizations,
		leagueTypes,
		reportPositions,
		reportIncidents,
		reportTypes,
		reportEvents,
	} = metadata.metadata;

	const [date, setDate] = useState(new Date(report.selected.date));

	const formValues = {
		typeId: report.selected.type.id,
		positionId: report.selected.position.id,
		leagueTypeId: report.selected.leagueType.id,
		incidentId: report.selected.incident.id,
		eventId: report.selected.event.id,
		organizationId: report.selected.organization.id,
	};

	if (report.selected.eventOther) {
		formValues.eventOther = report.selected.eventOther;
	}

	const [values, setValues] = useState(formValues);

	return (
		<Modal
			onExit={() => history.push('/rapporter')}
			titleText="Redigera en rapport"
			closeLink="/rapporter"
			heading={`Rapport - ID nr ${report.selected.id}`}
		>
			<Form
				initialValues={formValues}
				onSubmit={formValues => {
					const allValues = { ...formValues, date };
					props.updateReport(allValues, report.selected.id, history);
				}}
				onChange={(field, values) => setValues(values)}
			>
				<div className="smalltext">Av: {report.selected.user.email}</div>
				<div className="form__item form__item--Select">
					<label htmlFor="organizationId" className="form__label">
						Förening
					</label>
					<Select
						id="organizationId"
						name="organizationId"
						options={organizations || []}
					/>
				</div>
				<div className="form__item form__item--Select">
					<label htmlFor="leagueTypeId" className="form__label">
						Liga
					</label>
					<Select
						id="leagueTypeId"
						name="leagueTypeId"
						options={leagueTypes || []}
					/>
				</div>
				<div className="form__item form__item--Select">
					<label htmlFor="positionId" className="form__label">
						Position
					</label>
					<Select
						id="positionId"
						name="positionId"
						options={reportPositions || []}
					/>
				</div>
				<div className="form__item form__item--Select">
					<label htmlFor="incidentId" className="form__label">
						Huvudskada
					</label>
					<Select
						id="incidentId"
						name="incidentId"
						options={reportIncidents || []}
					/>
				</div>
				<div className="form__item form__item--Select">
					<label htmlFor="typeId" className="form__label">
						Skadetillfälle
					</label>
					<Select id="typeId" name="typeId" options={reportTypes || []} />
				</div>
				<div className="form__item form__item--Text">
					<label htmlFor="date" className="form__label">
						Datum
					</label>
					<DatePicker
						selected={date}
						placeholderText="Välj datum"
						onChange={newDate => setDate(newDate)}
						dateFormat="d MMMM yyyy"
						popperClassName="reportpage__calendar"
						locale={sv}
						isClearable={true}
						id="date"
					/>
				</div>
				<div className="form__item form__item--Select">
					<label htmlFor="eventId" className="form__label">
						Typ av händelse
					</label>
					<Select id="eventId" name="eventId" options={reportEvents || []} />
				</div>
				{(report.selected.eventOther || Number(values['eventId']) === 6) && (
					<div className="form__item form__item--Textarea">
						<label htmlFor="eventOther" className="form__label">
							Beskriv händelsen
						</label>
						<TextArea id="eventOther" name="eventOther" />
					</div>
				)}
				<div className="form__item">
					<Button type="submit" className="form__button form__button--Submit">
						Spara
					</Button>
					<Button
						type="button"
						className="form__button form__button--Submit form__button--Delete"
						onClick={e => {
							e.preventDefault();
							props.deleteReport(report.selected.id);
							history.push('/rapporter');
						}}
					>
						Ta bort
					</Button>
				</div>
			</Form>
		</Modal>
	);
};

const mapStateToProps = state => {
	return {
		report: state.report,
		metadata: state.metadata,
	};
};

export default connect(
	mapStateToProps,
	{ deleteReport, updateReport }
)(EditReportModal);
