/**
 * Form
 * @module components/Form
 * @version 1.0
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'formdata-polyfill';
import './Forms.scss';
import {
	Field,
	TextArea,
	Checkbox,
	RadioButton,
	Select,
	Button,
	Datepicker,
} from './';

class Form extends Component {
	constructor(props) {
		super(props);

		this.state = {
			values: props.initialValues || {},
			formKey: this.getUniqueKey(),
		};

		this.formFieldNameCounter = {};
		this.formRef = React.createRef();
	}

	/**
	 * We don't rerender when the components state changes.
	 */
	shouldComponentUpdate(nextProps, nextState) {
		return this.props !== nextProps || this.state.formKey !== nextState.formKey;
	}

	/**
	 * Generates a unique key (used by the reset-method).
	 */
	getUniqueKey() {
		return (
			'_' +
			Math.random()
				.toString(36)
				.substr(2, 9)
		);
	}

	/**
	 * Method that submits the form.
	 */
	onSubmit = e => {
		const { sendDataType, onSubmit, defaultSubmit } = this.props;
		let { values } = this.state;

		if (sendDataType === 'formdata' && typeof FormData === 'undefined') {
			return true;
		}

		if (e && !defaultSubmit) {
			e.preventDefault();
		}

		if (sendDataType === 'formdata') {
			values = new FormData(this.formRef.current);
		}

		if (
			values.leagueTypes &&
			(values.leagueTypes[0] && values.leagueTypes[0].value)
		) {
			values.leagueTypes = values.leagueTypes.map(item => item.value);
		}

		if (onSubmit) {
			onSubmit(values);
		}
	};

	/**
	 * Callback when a fields value changes.
	 */
	onFieldChange = ({ name, value, type, autoSubmit, checked }) => {
		const { values } = this.state;
		const valueIsArray =
			values[name] && Array.isArray(values[name]) && checked !== undefined;

		let newValue = value;

		// Handle checkboxes when they have array values
		if (type === 'Checkbox') {
			if (valueIsArray) {
				newValue = checked
					? [...values[name], value]
					: values[name].filter(item => item !== value);
			} else if (this.formFieldNameCounter[name] > 1 && checked !== undefined) {
				newValue = [value];
			} else if (checked === false) {
				newValue = '';
			}
		}

		this.setState(
			{ values: { ...this.state.values, [name]: newValue } },
			() => {
				if (autoSubmit) {
					this.onSubmit();
				}

				if (this.props.onChange) {
					this.props.onChange({ name, value: newValue }, this.state.values);
				}
			}
		);
	};

	/**
	 * Resets the form to its initial state.
	 * Uses a trick where we change the form key to completely rerender it.
	 */
	resetForm = () => {
		this.setState({ formKey: this.getUniqueKey() });
		if (this.props.onReset) {
			this.props.onReset();
		}
	};

	/**
	 * Returns true if element is a Form field type.
	 */
	isValidFormElement(element) {
		switch (element.type) {
			case Checkbox:
			case Field:
			case TextArea:
			case RadioButton:
			case Button:
			case Select:
			case Datepicker:
				return true;
			default:
				return false;
		}
	}

	/**
	 * Recursively renders each child and adding props to Form fields children.
	 */
	renderChildren(children, initialValues) {
		if (!children) {
			return null;
		}

		const renderedChildren = React.Children.map(children, child => {
			const props = child && child.props ? { ...child.props } : {};

			if (!React.isValidElement(child)) {
				return child;
			}

			if (this.isValidFormElement(child)) {
				const { name } = child.props;

				props.onFieldChange = this.onFieldChange;
				props.resetForm = this.resetForm;

				if (initialValues[name]) {
					props.defaultValue = initialValues[name];
				}

				if (!this.formFieldNameCounter[name]) {
					this.formFieldNameCounter[name] = 1;
				} else {
					this.formFieldNameCounter[name] += 1;
				}
			}

			if (child.props && child.props.children) {
				props.children = this.renderChildren(
					child.props.children,
					initialValues
				);
			}

			return React.cloneElement(child, props);
		});

		if (renderedChildren.length === 1) {
			return renderedChildren[0];
		} else {
			return renderedChildren;
		}
	}

	render() {
		const {
			children,
			method,
			action,
			enctype,
			initialValues,
			className,
			id,
		} = this.props;
		const { formKey } = this.state;

		return (
			<form
				key={formKey}
				className={className}
				method={method}
				action={action}
				encType={enctype}
				onSubmit={this.onSubmit}
				ref={this.formRef}
				id={id}
			>
				{this.renderChildren(children, initialValues)}
			</form>
		);
	}
}

Form.propTypes = {
	initialValues: PropTypes.object,
	method: PropTypes.string,
	action: PropTypes.string,
	enctype: PropTypes.string,
	sendDataType: PropTypes.string,
	onSubmit: PropTypes.func,
	onChange: PropTypes.func,
	defaultSubmit: PropTypes.bool,
	onReset: PropTypes.func,
};

Form.defaultProps = {
	method: 'post',
	action: '',
	enctype: 'application/x-www-form-urlencoded',
};

export default Form;
