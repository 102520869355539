/**
 * ErrorPage
 * @module pages/ErrorPage
 */

import React from 'react';
import './ErrorPage.scss';
import { Link } from 'react-router-dom';

import StandardLayout from 'layouts/StandardLayout';
import Layout from 'components/Layout';

const ErrorPage = ({ location }) => (
	<StandardLayout location={location}>
		<main id="main-content" className="errorpage">
			<Layout>
				<h1 className="errorpage__heading">
					Hoppsan! Någonting gick visst fel.
				</h1>
				<p className="errorpage__text">
					Det verkar som att sidan du försöker att nå inte går att hitta. Gå
					tillbaka till alla <Link to="/rapporter">rapporter</Link> eller{' '}
					<Link to="/anvandare">användare</Link> och försök igen.
				</p>
			</Layout>
		</main>
	</StandardLayout>
);

export default ErrorPage;
