import fetch from 'unfetch';
import { API_URL, TOKEN_NAME } from 'common/config';
import {
	USER_FETCH,
	USER_SORT,
	USER_SEARCH,
	USER_CREATE,
	USER_UPDATE,
	USER_DELETE,
	USER_SELECT,
	USER_ERROR,
} from 'actions/types';

/**
 * Action for fetching all users
 * @param {String} url - If we have a url, we're requesting a specific page
 */
export const fetchUsers = url => {
	const token = JSON.parse(localStorage.getItem(TOKEN_NAME)).token;
	const requestUrl = url ? `${API_URL}${url}` : `${API_URL}/User`;

	return dispatch => {
		return fetch(requestUrl, {
			cache: 'no-cache',
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json-patch+json',
			},
			method: 'GET',
			credentials: 'same-origin',
			redirect: 'follow',
			referrer: 'no-referrer',
		})
			.then(response => response.json())
			.then(response => {
				if (response.items) {
					dispatch({
						type: USER_FETCH,
						payload: response,
					});
				} else {
					dispatch(usersError("Couldn't fetch users."));
				}
			})
			.catch(() => {
				dispatch(usersError("Couldn't fetch users."));
			});
	};
};

/**
 * Action for sorting users
 * @param {String} orderby - Field to order the list by
 * @param {Boolean} order - true = ascending, false = descending
 */
export const sortUsers = (orderby, order) => {
	const token = JSON.parse(localStorage.getItem(TOKEN_NAME)).token;

	return dispatch => {
		return fetch(`${API_URL}/User?orderby=${orderby}&order=${order}`, {
			cache: 'no-cache',
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json-patch+json',
			},
			method: 'GET',
			credentials: 'same-origin',
			redirect: 'follow',
			referrer: 'no-referrer',
		})
			.then(response => response.json())
			.then(response => {
				dispatch({
					type: USER_SORT,
					payload: response,
				});
			})
			.catch(() => {
				dispatch(usersError(`Couldn't sort users by ${orderby}.`));
			});
	};
};

/**
 * Action for searching users
 * @param {String} query - String to search
 */
export const searchUser = query => {
	const token = JSON.parse(localStorage.getItem(TOKEN_NAME)).token;

	return dispatch => {
		return fetch(`${API_URL}/User?search=${query}`, {
			cache: 'no-cache',
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json-patch+json',
			},
			method: 'GET',
			credentials: 'same-origin',
			redirect: 'follow',
			referrer: 'no-referrer',
		})
			.then(response => response.json())
			.then(response => {
				dispatch({
					type: USER_SEARCH,
					payload: response,
				});
			})
			.catch(() => {
				dispatch(usersError(`Couldn't search users by ${query}.`));
			});
	};
};

/**
 * Action for selecting a user
 * @param {Array} user - The selected user
 */
export const selectUser = user => {
	return dispatch => {
		dispatch({
			type: USER_SELECT,
			payload: user,
		});
	};
};

/**
 * Action for creating a user
 * @param {Object} user - Values for the new user
 * @param {Object} history
 */
export const createUser = (user, history) => {
	const token = JSON.parse(localStorage.getItem(TOKEN_NAME)).token;

	return dispatch => {
		return fetch(`${API_URL}/User`, {
			body: JSON.stringify(user),
			cache: 'no-cache',
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json-patch+json',
			},
			method: 'POST',
			credentials: 'same-origin',
			redirect: 'follow',
			referrer: 'no-referrer',
		})
			.then(response => response.json())
			.then(response => {
				if (response.id) {
					dispatch({
						type: USER_CREATE,
						payload: response,
					});
					history.push('/anvandare');
				} else {
					dispatch(usersError(response.message || `Couldn't create user.`));
				}
			})
			.catch(() => {
				dispatch(usersError(`Couldn't create user.`));
			});
	};
};

/**
 * Action for updating a user
 * @param {Object} user - The user to update
 * @param {Object} history
 */
export const updateUser = (user, history) => {
	const token = JSON.parse(localStorage.getItem(TOKEN_NAME)).token;

	return dispatch => {
		return fetch(`${API_URL}/User/${user.id}`, {
			body: JSON.stringify(user),
			cache: 'no-cache',
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json-patch+json',
			},
			method: 'PATCH',
			credentials: 'same-origin',
			redirect: 'follow',
			referrer: 'no-referrer',
		})
			.then(response => response.json())
			.then(response => {
				if (response.id) {
					dispatch({
						type: USER_UPDATE,
						payload: response,
					});
					history.push('/anvandare');
				} else {
					dispatch(usersError(`Couldn't update user with id ${user.id}.`));
				}
			})
			.catch(() => {
				dispatch(usersError(`Couldn't update user with id ${user.id}.`));
			});
	};
};

/**
 * Action for deleting a user
 * @param {Number} userId - Id of the user to delete
 */
export const deleteUser = userId => {
	const token = JSON.parse(localStorage.getItem(TOKEN_NAME)).token;

	return dispatch => {
		return fetch(`${API_URL}/User/${userId}`, {
			cache: 'no-cache',
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json-patch+json',
			},
			method: 'DELETE',
			credentials: 'same-origin',
			redirect: 'follow',
			referrer: 'no-referrer',
		})
			.then(response => {
				if (response.status === 200) {
					dispatch({
						type: USER_DELETE,
						payload: userId,
					});
				} else {
					dispatch(usersError(`Couldn't delete user with id ${userId}.`));
				}
			})
			.catch(() => {
				dispatch(usersError(`Couldn't delete user with id ${userId}.`));
			});
	};
};

/**
 * Action for dispatching a users error
 */
export function usersError(error) {
	return {
		type: USER_ERROR,
		payload: error,
	};
}
