/**
 * UsersPage
 * @module pages/UsersPage
 */

import React, { useState, useEffect } from 'react';
import './UsersPage.scss';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import StandardLayout from 'layouts/StandardLayout';
import Layout from 'components/Layout';
import Icon from 'components/Icon';
import LinkButton from 'components/Button';
import EditUserModal from './EditUserModal';
import { Table, TableBody, TableHead, TableHeading } from 'components/Table';
import { Form, Button, Field } from 'components/Form';
import Pager from 'components/Pager';

import {
	fetchUsers,
	sortUsers,
	searchUser,
	selectUser,
} from 'actions/user.actions';
import { selectOrganization } from 'actions/metadata.actions';

const UsersPage = ({
	openModal,
	location,
	history,
	user,
	metadata,
	...props
}) => {
	const [showDialog, setShowDialog] = useState(false);
	const [sortQuery, setSort] = useState('');

	useEffect(() => {
		const shouldOpenModal = location.state
			? location.state.openModal
			: openModal;
		setShowDialog(shouldOpenModal);

		if (location.state) {
			props.selectUser(location.state.selectedUser);
		}
	}, [location]);

	/** Handles table column sort */
	const onColumnSort = (prop, sort) => {
		if (prop === 'organization') {
			props.sortUsers(`${prop}.name`, sort === 'up');
			setSort(`&orderby=${prop}.name&order=${sort === 'up'}`);
		} else {
			props.sortUsers(prop, sort === 'up');
			setSort(`&orderby=${prop}&order=${sort === 'up'}`);
		}
	};

	const usersArray =
		user.users.items && user.users.items.length > 0 ? user.users.items : [];

	return (
		<StandardLayout>
			<main id="main-content" className="userspage">
				<section className="userspage__section--top">
					<Layout color="grey-light">
						<div className="userspage__section">
							<div class="userspage__flex">
								<Form
									onSubmit={values => props.searchUser(values.query)}
									initialValues={{}}
								>
									<label htmlFor="query" className="userspage__subheading">
										Sök användare
									</label>
									<div className="form__item--Search userspage__input">
										<Field name="query" id="query" />
										<Button
											type="submit"
											className="form__button form__button--Submit"
										>
											Sök
										</Button>
									</div>
								</Form>
								<LinkButton
									type="route"
									url="/anvandare/ny"
									color="blue"
									state={{ openModal: true }}
								>
									<span className="userspage__button">
										<Icon icon="icon-plus" />
										Ny användare
									</span>
								</LinkButton>
							</div>
						</div>
					</Layout>
				</section>

				<section className="userspage__section">
					<Layout color="white">
						<h1 className="userspage__heading">Användare – rapportörer</h1>
						<Table caption="Registrerade användare" scrollable>
							<TableHead>
								<TableHeading
									id="lastName"
									text="Efternamn"
									sortable
									onSort={onColumnSort}
								/>
								<TableHeading
									id="firstName"
									text="Förnamn"
									sortable
									onSort={onColumnSort}
								/>
								<TableHeading
									id="organization"
									text="Förening"
									sortable
									onSort={onColumnSort}
								/>
								<TableHeading text="E-post" />
								<TableHeading text="Telefon" />
							</TableHead>
							<TableBody>
								{usersArray.map((user, i) => (
									<tr
										key={i}
										id={`id-${user.id}`}
										onClick={() =>
											document.querySelector(`#id-${user.id} a`).click()
										}
									>
										<td>
											<Link
												to={{
													pathname: `/anvandare/${user.id}`,
													state: { selectedUser: user, openModal: true },
												}}
											>
												{user.lastName}
											</Link>
										</td>
										<td>{user.firstName}</td>
										<td>{user.organization ? user.organization.name : ''}</td>
										<td>{user.email}</td>
										<td>{user.phone}</td>
									</tr>
								))}
							</TableBody>
						</Table>

						<div className="reportspage__pagination">
							<Pager
								nrOfPages={user.users.pageCount ? user.users.pageCount : 1}
								currentPage={user.users.page ? user.users.page : 1}
								onPageChange={url => props.fetchUsers(`${url}${sortQuery}`)}
								url="/User"
							/>
							<p className="reportspage__counter">
								Sida: {user.users.page} av {user.users.pageCount}
							</p>
						</div>
					</Layout>
				</section>

				{showDialog && <EditUserModal history={history} />}
			</main>
		</StandardLayout>
	);
};

const mapStateToProps = state => {
	return {
		user: state.user,
		metadata: state.metadata,
	};
};

export default connect(
	mapStateToProps,
	{ fetchUsers, sortUsers, searchUser, selectUser, selectOrganization }
)(UsersPage);
