/**
 * MainMenu
 * @module components/MainMenu
 */

import React from 'react';
import './MainMenu.scss';
import { NavLink } from 'react-router-dom';

const MainMenu = React.memo(() => {
	return (
		<nav className="mainmenu" aria-label="Huvudmeny">
			<ul className="mainmenu__list">
				<li className="mainmenu__list__item">
					<NavLink to="/rapporter" activeClassName="active">
						Rapporter
					</NavLink>
				</li>
				<li className="mainmenu__list__item">
					<NavLink to="/anvandare" activeClassName="active">
						Användare
					</NavLink>
				</li>
				<li className="mainmenu__list__item">
					<NavLink to="/forening" activeClassName="active">
						Förening
					</NavLink>
				</li>
			</ul>
		</nav>
	);
});

export default MainMenu;
