/**
 * ReportsPage
 * @module pages/ReportsPage
 */

import React, { useState, useEffect } from 'react';
import './ReportsPage.scss';
import { connect } from 'react-redux';

import StandardLayout from 'layouts/StandardLayout';
import Layout from 'components/Layout';
import LinkButton from 'components/Button';
import Pager from 'components/Pager';
import Filters from 'components/Filters';

import EditReportModal from './EditReportModal';
import ReportListItem from './ReportListItem';
import { Table, TableBody, TableHead, TableHeading } from 'components/Table';

import {
	fetchReports,
	sortReports,
	selectReport,
	exportReports,
	filterReports,
} from 'actions/report.actions';
import { getQueryString } from 'common/helpers';

const ReportsPage = ({
	openModal,
	location,
	history,
	report,
	metadata,
	...props
}) => {
	const [showDialog, setShowDialog] = useState(false);
	const [sortQuery, setSort] = useState('');
	const [query, setQuery] = useState('');

	useEffect(() => {
		const shouldOpenModal = location.state
			? location.state.openModal
			: openModal;
		setShowDialog(shouldOpenModal);

		if (location.state) {
			props.selectReport(location.state.selectedUser);
		}
	}, [location]);

	/** Handles table column sort */
	const onColumnSort = (prop, sort) => {
		if (prop === 'date' || prop === 'id') {
			props.sortReports(prop, sort === 'up', query);
			setSort(`&orderby=${prop}&order=${sort === 'up'}`);
		} else {
			props.sortReports(`${prop}.name`, sort === 'up', query);
			setSort(`&orderby=${prop}.name&order=${sort === 'up'}`);
		}
	};

	const handleFormSubmit = data => {
		let query = getQueryString(data);
		props.filterReports(query);

		if (query[0] === '?') {
			query = query.slice(1);
		}
		setQuery(query);
	};

	return (
		<StandardLayout>
			<main id="main-content" className="reportspage">
				<section className="userspage__section--top">
					<Layout color="grey-light">
						<Filters
							metadata={metadata.metadata}
							onSubmit={handleFormSubmit}
							onReset={() => props.fetchReports()}
						/>
					</Layout>
				</section>

				<section className="reportspage__section">
					<Layout color="white">
						<h1 className="reportspage__heading">Rapporter</h1>

						<Table caption="Registrerade användare" scrollable>
							<TableHead>
								<TableHeading
									id="id"
									text="ID nr"
									sortable
									onSort={onColumnSort}
								/>
								<TableHeading
									id="leagueType"
									text="Liga"
									sortable
									onSort={onColumnSort}
								/>
								<TableHeading
									id="organization"
									text="Förening"
									sortable
									onSort={onColumnSort}
								/>
								<TableHeading
									id="position"
									text="Position"
									sortable
									onSort={onColumnSort}
								/>
								<TableHeading
									id="incident"
									text="Status"
									sortable
									onSort={onColumnSort}
								/>
								<TableHeading
									id="type"
									text="Skadetillfälle"
									sortable
									onSort={onColumnSort}
								/>
								<TableHeading
									id="date"
									text="Datum"
									sortable
									onSort={onColumnSort}
								/>
								<TableHeading
									id="event"
									text="Händelse"
									sortable
									onSort={onColumnSort}
								/>
							</TableHead>
							<TableBody>
								{report.reports.items && report.reports.items.length > 0 ? (
									report.reports.items.map((report, i) => (
										<ReportListItem report={report} key={i} />
									))
								) : (
									<tr>
										<td>Inga rapporter att visa.</td>
										<td />
										<td />
										<td />
										<td />
										<td />
										<td />
										<td />
									</tr>
								)}
							</TableBody>
						</Table>

						<div className="reportspage__pagination">
							<Pager
								nrOfPages={
									report.reports.pageCount ? report.reports.pageCount : 1
								}
								currentPage={report.reports.page ? report.reports.page : 1}
								onPageChange={url =>
									props.fetchReports(`${url}${sortQuery}&${query}`)
								}
								url="/Report"
							/>
							<p className="reportspage__counter">
								Sida: {report.reports.page} av {report.reports.pageCount}
							</p>
						</div>

						<div className="reportspage__buttons">
							<div>
								<LinkButton
									type="button"
									url="/rapportera"
									color="blue"
									onClick={e => {
										e.preventDefault();
										props.fetchReports();
									}}
								>
									Uppdatera
								</LinkButton>
							</div>
							<LinkButton
								type="button"
								url="/rapportera"
								color="blue"
								onClick={e => {
									e.preventDefault();
									props.exportReports('xlsx');
								}}
							>
								Exportera .xls
							</LinkButton>
							<LinkButton
								type="button"
								url="/rapportera"
								color="blue"
								onClick={e => {
									e.preventDefault();
									props.exportReports('csv');
								}}
							>
								Exportera .csv
							</LinkButton>
						</div>
					</Layout>
				</section>

				{showDialog && <EditReportModal history={history} />}
			</main>
		</StandardLayout>
	);
};

const mapStateToProps = state => {
	return {
		report: state.report,
		metadata: state.metadata,
	};
};

export default connect(
	mapStateToProps,
	{
		fetchReports,
		sortReports,
		selectReport,
		exportReports,
		filterReports,
	}
)(ReportsPage);
