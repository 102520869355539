/**
 * Modal
 * @module components/Modal
 */

import React from 'react';
import PropTypes from 'prop-types';
import AriaModal from 'react-aria-modal';
import { Link } from 'react-router-dom';
import './Modal.scss';

import Icon from 'components/Icon';

const Modal = ({ heading, onExit, titleText, children, closeLink }) => {
	return (
		<AriaModal
			onExit={onExit}
			titleText={titleText}
			underlayClickExists={false}
			focusDialog={true}
			verticallyCenter={true}
		>
			<div className="modal">
				<Link to={closeLink} className="modal__close">
					<Icon icon="icon-x" color="white" />
					<span className="is-visuallyhidden">Stäng fönster</span>
				</Link>

				<h1 className="modal__heading">{heading}</h1>

				{children}
			</div>
		</AriaModal>
	);
};

Modal.propTypes = {
	heading: PropTypes.string,
	titleText: PropTypes.string.isRequired,
	closeLink: PropTypes.string,
	onExit: PropTypes.func,
};

export default Modal;
