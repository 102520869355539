/**
 * EditOrganizationModal
 * @module pages/EditOrganizationModal
 */

import {
	createOrganization,
	deleteOrganization,
	updateOrganization,
} from 'actions/organization.actions';
import { Button, Field, Form, Select } from 'components/Form';
import Modal from 'components/Modal';
import React, { useState } from 'react';
import { connect } from 'react-redux';

const EditOrganizationModal = ({
	history,
	organization,
	metadata,
	...props
}) => {
	const [organzationError, setOrganizationError] = useState(false);

	const handleSubmit = values => {
		if (!Array.isArray(values.leagueTypes)) {
			values.leagueTypes = [values.leagueTypes];
		}
		if (values.organizationName && values.organizationName.length < 0) {
			setOrganizationError(true);
		} else if (organization.selected) {
			props.updateOrganization(values, history);
		} else {
			props.createOrganization(values, history);
		}
	};

	return (
		<Modal
			onExit={() => history.push('/forening')}
			titleText="Skapa en ny förening eller redigera befintlig förening"
			closeLink="/forening"
			heading="Förening"
		>
			<Form
				initialValues={{
					...organization.selected,
				}}
				onSubmit={handleSubmit}
			>
				<div className="form__item form__item--Text">
					<label htmlFor="name" className="form__label">
						Föreningsnamn
					</label>
					<Field name="name" id="name" required />
					{organzationError && (
						<p className="organizationspage__error">
							Föreningsnamnet måste anges för att kunna skapa en ny förening.
						</p>
					)}
				</div>

				<div className="form__item form__item--Multiple">
					<label htmlFor="leagueTypes" className="form__label">
						Ligor
					</label>
					<Select
						multiple
						id="leagueTypes"
						name="leagueTypes"
						options={metadata.metadata.leagueTypes || []}
					/>
				</div>

				<div className="form__item">
					{organization.error && (
						<p className="organizationpage__error">{organization.error}</p>
					)}

					<Button type="submit" className="form__button form__button--Submit">
						Spara
					</Button>
					<Button
						type="button"
						className="form__button form__button--Submit form__button--Delete"
						onClick={e => {
							e.preventDefault();
							props.deleteOrganization(organization.selected.id);
							history.push('/forening');
						}}
					>
						Ta bort
					</Button>
				</div>
			</Form>
		</Modal>
	);
};

const mapStateToProps = state => {
	return {
		organization: state.organization,
		metadata: state.metadata,
	};
};

export default connect(
	mapStateToProps,
	{ deleteOrganization, updateOrganization, createOrganization }
)(EditOrganizationModal);
