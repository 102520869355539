/**
 * OrganizationPage
 * @module pages/OrganizationPage
 */

import React, { useState, useEffect } from 'react';
import './OrganizationPage.scss';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import StandardLayout from 'layouts/StandardLayout';
import Layout from 'components/Layout';
import Icon from 'components/Icon';
import LinkButton from 'components/Button';
import EditOrganizationModal from './EditOrganizationModal';
import { Table, TableBody, TableHead, TableHeading } from 'components/Table';
import { Field, Button, Form } from 'components/Form';
import Pager from 'components/Pager';

import {
	fetchOrganizations,
	sortOrganizations,
	searchOrganizations,
	selectOrganization,
} from 'actions/organization.actions';
import { selectLeague } from 'actions/metadata.actions';

const OrganizationPage = ({
	openModal,
	location,
	history,
	organization,
	metadata,
	...props
}) => {
	const [showDialog, setShowDialog] = useState(false);
	const [sortQuery, setSort] = useState('');

	useEffect(() => {
		const shouldOpenModal = location.state
			? location.state.openModal
			: openModal;
		setShowDialog(shouldOpenModal);

		if (location.state) {
			props.selectOrganization(location.state.selectedOrganization);
		}
	}, [location]);

	/** Handles table column sort */
	const onColumnSort = (prop, sort) => {
		props.sortOrganizations('name', sort === 'up');
		setSort(`&orderby=name&order=${sort === 'up'}`);
	};

	const organizationsArray =
		organization.organizations.items &&
		organization.organizations.items.length > 0
			? organization.organizations.items
			: [];

	return (
		<StandardLayout>
			<main id="main-content" className="organizationspage">
				<section className="organizationspage__section--top">
					<Layout color="grey-light">
						<div className="organizationspage__section">
							<div class="organizationspage__flex">
								<Form
									onSubmit={values => props.searchOrganizations(values.query)}
									initialValues={{}}
								>
									<label
										htmlFor="query"
										className="organizationspage__subheading"
									>
										Sök på förening
									</label>
									<div className="form__item--Search organizationspage__input">
										<Field name="query" id="query" />
										<Button
											type="submit"
											className="form__button form__button--Submit"
										>
											Sök
										</Button>
									</div>
								</Form>
								<LinkButton
									type="route"
									url="/forening/ny"
									color="blue"
									state={{ openModal: true }}
								>
									<span className="organizationspage__button">
										<Icon icon="icon-plus" />
										Ny förening
									</span>
								</LinkButton>
							</div>
						</div>
					</Layout>
				</section>

				<section className="organizationspage__section">
					<Layout color="white">
						<h1 className="organizationspage__heading">Föreningar</h1>
						<Table caption="Registrerade föreningar">
							<TableHead>
								<TableHeading
									id="organization"
									text="Förening"
									sortable
									onSort={onColumnSort}
								/>
							</TableHead>
							<TableBody>
								{organizationsArray.map((organization, i) => (
									<tr
										key={i}
										id={`id-${organization.id}`}
										onClick={() =>
											document.querySelector(`#id-${organization.id} a`).click()
										}
									>
										<td>
											<Link
												to={{
													pathname: `/forening/${organization.id}`,
													state: {
														selectedOrganization: organization,
														openModal: true,
													},
												}}
											>
												{organization.name}
											</Link>
										</td>
									</tr>
								))}
							</TableBody>
						</Table>

						<div className="organizationPage__pagination">
							<Pager
								nrOfPages={
									organization.organizations.pageCount
										? organization.organizations.pageCount
										: 1
								}
								currentPage={
									organization.organizations.page
										? organization.organizations.page
										: 1
								}
								onPageChange={url =>
									props.fetchOrganizations(`${url}${sortQuery}`)
								}
								url="/Organization"
							/>
							<p className="organizationPage__counter">
								Sida: {organization.organizations.page} av{' '}
								{organization.organizations.pageCount}
							</p>
						</div>
					</Layout>
				</section>

				{showDialog && <EditOrganizationModal history={history} />}
			</main>
		</StandardLayout>
	);
};

const mapStateToProps = state => {
	return {
		organization: state.organization,
		metadata: state.metadata,
	};
};

export default connect(
	mapStateToProps,
	{
		fetchOrganizations,
		sortOrganizations,
		searchOrganizations,
		selectOrganization,
		selectLeague,
	}
)(OrganizationPage);
