/**
 * Pager
 * @module components/Pager
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Pager.scss';

import Icon from 'components/Icon';

class Pager extends Component {
	/**
	 * Handles page change when a new page is clicked
	 * @param {Object} e - The event object
	 * @param {String} url - The url to send the request to
	 */
	handlePageChange(e, url) {
		e.preventDefault();

		this.props.onPageChange(url);
	}

	/**
	 * Returns the array of page numbers to display, based on current page
	 * @returns {Array}
	 */
	getPageNumbers() {
		const { currentPage, nrOfPages } = this.props;
		let numbers = [];

		if (currentPage >= 1) {
			numbers.push(currentPage);
		}

		if (currentPage >= 3) {
			numbers.push(currentPage - 1);
			numbers.push(currentPage - 2);
		}

		if (currentPage + 2 <= nrOfPages) {
			numbers.push(currentPage + 2);
			numbers.push(currentPage + 1);
		}

		numbers.sort((a, b) => a - b);

		return numbers;
	}

	/**
	 * Adds link to last page if it doesn't already exist, and '...' if needed
	 * @param {Array} pageNumbers - Array of page numbers to display
	 * @param {Array} pages - Array of pagination list elements
	 */
	getLastLink(pageNumbers, pages) {
		const { nrOfPages, currentPage, url } = this.props;

		if (!pageNumbers.includes(nrOfPages)) {
			if (!pageNumbers.includes(nrOfPages - 1)) {
				pages.push(
					<li
						className="pagination__item pagination__item--empty"
						key={nrOfPages - 1}
					>
						...
					</li>
				);
			}

			const className =
				currentPage === nrOfPages
					? 'pagination__item pagination__item--active'
					: 'pagination__item';

			pages.push(
				<li className={className} key={nrOfPages}>
					<a
						href={`?page=${nrOfPages}`}
						onClick={e => this.handlePageChange(e, `${url}?page=${nrOfPages}`)}
					>
						{nrOfPages}
					</a>
				</li>
			);
		}

		return pages;
	}

	/**
	 * Loops through numbers array and adds JSX element for each number
	 * @param {Array} pageNumbers - Array of page numbers to display
	 * @param {Array} pages - Array of pagination list elements
	 */
	getLinks(pageNumbers, pages) {
		const { currentPage, url } = this.props;

		pageNumbers.forEach(number => {
			if (number === 1) {
				return;
			}

			const className =
				currentPage === number
					? 'pagination__item pagination__item--active'
					: 'pagination__item';

			pages.push(
				<li className={className} key={number}>
					<a
						href={`?page=${number}`}
						onClick={e => this.handlePageChange(e, `${url}?page=${number}`)}
					>
						{number}
					</a>
				</li>
			);
		});

		return pages;
	}

	/**
	 * Adds link to first page if it doesn't already exist, and '...' if needed
	 * @param {Array} pageNumbers - Array of page numbers to display
	 * @param {Array} pages - Array of pagination list elements
	 */
	getFirstLink(pageNumbers, pages) {
		const { currentPage, url } = this.props;

		if (!pageNumbers.includes(0)) {
			const className =
				currentPage === 1
					? 'pagination__item pagination__item--active'
					: 'pagination__item';

			pages.push(
				<li className={className} key={0}>
					<a
						href="?page=1"
						onClick={e => this.handlePageChange(e, `${url}?page=1`)}
					>
						1
					</a>
				</li>
			);

			if (pageNumbers.length > 1 && !pageNumbers.includes(2)) {
				pages.push(
					<li className="pagination__item pagination__item--empty" key={-1}>
						...
					</li>
				);
			}
		}

		return pages;
	}

	/**
	 * Gets JSX for all page numbers
	 * @param {Array} pageNumbers - The page numbers to render
	 * @param {Array} pages - Array of pagination links (JSX)
	 */
	getPageLinks(pageNumbers, pages) {
		pages = this.getFirstLink(pageNumbers, pages);
		pages = this.getLinks(pageNumbers, pages);
		pages = this.getLastLink(pageNumbers, pages);

		return pages;
	}

	/**
	 * Returns a Pagination button for next/previous behavior
	 * @param {JSX} content - Content to render inside link
	 * @param {String} pageNumber - Page number to go to
	 * @param {String} key - Element key
	 * @param {String} title - Title to make link accessible
	 * @returns {JSX}
	 */
	getPagerBtn(content, pageNumber, key, title) {
		return (
			<li className="pagination__item" key={key}>
				<a
					href={`?page=${pageNumber}`}
					onClick={e =>
						this.handlePageChange(e, `${this.props.url}?page=${pageNumber}`)
					}
					aria-label={title}
				>
					{content}
				</a>
			</li>
		);
	}

	/**
	 * Renders the pagination
	 * @param {Array} pageNumbers - Array of page numbers to render
	 */
	renderPagination(pageNumbers) {
		const { nrOfPages, currentPage } = this.props;

		const shouldRenderJumpToFirstPageBtn = currentPage !== 1 && currentPage > 2;
		const shouldRenderPreviousBtn = currentPage !== 1;

		const shouldRenderNextBtn = currentPage < nrOfPages;
		const shouldRenderJumpToLastPageBtn =
			currentPage < nrOfPages && currentPage !== nrOfPages - 1;

		let pages = [];

		if (shouldRenderJumpToFirstPageBtn) {
			pages.push(
				this.getPagerBtn(
					<Icon icon="icon-chevrons-left" />,
					1,
					'first',
					'Första sidan'
				)
			);
		}

		if (shouldRenderPreviousBtn) {
			pages.push(
				this.getPagerBtn(
					<Icon icon="icon-chevron-left" />,
					currentPage - 1,
					'prev',
					'Föregående sida'
				)
			);
		}

		pages = this.getPageLinks(pageNumbers, pages);

		if (shouldRenderNextBtn) {
			pages.push(
				this.getPagerBtn(
					<Icon icon="icon-chevron-right" />,
					currentPage + 1,
					'next',
					'Nästa sida'
				)
			);
		}

		if (shouldRenderJumpToLastPageBtn) {
			pages.push(
				this.getPagerBtn(
					<Icon icon="icon-chevrons-right" />,
					nrOfPages,
					'last',
					'Sista sidan'
				)
			);
		}

		return pages;
	}

	/**
	 * The render method
	 */
	render() {
		const pageNumbers = this.getPageNumbers();

		return (
			<nav className="pagination" aria-label="Paginering">
				<ul className="pagination__list">
					{this.renderPagination(pageNumbers)}
				</ul>
			</nav>
		);
	}
}

Pager.propTypes = {
	nrOfPages: PropTypes.number,
	currentPage: PropTypes.number,
	onPageChange: PropTypes.func,
	url: PropTypes.string,
};

export default Pager;
