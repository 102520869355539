import fetch from 'unfetch';
import { API_URL, TOKEN_NAME } from 'common/config';
import {
	ORGANIZATION_FETCH,
	ORGANIZATION_SORT,
	ORGANIZATION_SEARCH,
	ORGANIZATION_CREATE,
	ORGANIZATION_UPDATE,
	ORGANIZATION_DELETE,
	ORGANIZATION_SELECT,
	ORGANIZATION_ERROR,
} from 'actions/types';

/**
 * Action for fetching all organizations
 * @param {String} url - If we have a url, we're requesting a specific page
 */

export const fetchOrganizations = url => {
	const token = JSON.parse(localStorage.getItem(TOKEN_NAME)).token;
	const requestUrl = url ? `${API_URL}${url}` : `${API_URL}/Organization`;

	return dispatch => {
		return fetch(requestUrl, {
			cache: 'no-cache',
			headers: {
				'X-Requested-With': 'CMLHttpRequest',
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json-patch+json',
			},
			method: 'GET',
			credentials: 'same-origin',
			redirect: 'follow',
			referrer: 'no-referrer',
		})
			.then(response => response.json())
			.then(response => {
				if (response.items) {
					dispatch({
						type: ORGANIZATION_FETCH,
						payload: response,
					});
				} else {
					dispatch(organizationsError("Couldn't fetch organizations."));
				}
			})
			.catch(() => {
				dispatch(organizationsError("Couldn't fetch organizations."));
			});
	};
};

/**
 * Action for sorting organizations
 * @param {String} orderby - Field to order the list by
 * @param {Boolean} order - true = ascending, false = descending
 */
export const sortOrganizations = (orderby, order) => {
	const token = JSON.parse(localStorage.getItem(TOKEN_NAME)).token;

	return dispatch => {
		return fetch(`${API_URL}/Organization?orderby=${orderby}&order=${order}`, {
			cache: 'no-cache',
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json-patch+json',
			},
			method: 'GET',
			credentials: 'same-origin',
			redirect: 'follow',
			referrer: 'no-referrer',
		})
			.then(response => response.json())
			.then(response => {
				dispatch({
					type: ORGANIZATION_SORT,
					payload: response,
				});
			})
			.catch(() => {
				dispatch(
					organizationsError(`Couldn't sort organizations by ${orderby}.`)
				);
			});
	};
};

/**
 * Action for searching organizations
 * @param {String} query - String to search
 */
export const searchOrganizations = query => {
	const token = JSON.parse(localStorage.getItem(TOKEN_NAME)).token;

	return dispatch => {
		return fetch(`${API_URL}/Organization?search=${query}`, {
			cache: 'no-cache',
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json-patch+json',
			},
			method: 'GET',
			credentials: 'same-origin',
			redirect: 'follow',
			referrer: 'no-referrer',
		})
			.then(response => response.json())
			.then(response => {
				dispatch({
					type: ORGANIZATION_SEARCH,
					payload: response,
				});
			})
			.catch(() => {
				dispatch(
					organizationsError(`Couldn't search organizations by ${query}.`)
				);
			});
	};
};

/**
 * Action for selecting a organization
 * @param {Array} organization - The selected organization
 */
export const selectOrganization = organization => {
	return dispatch => {
		dispatch({
			type: ORGANIZATION_SELECT,
			payload: organization,
		});
	};
};

/**
 * Action for creating a organization
 * @param {Object} organization - Values for the new organization
 * @param {Object} history
 */

export const createOrganization = (organization, history) => {
	const token = JSON.parse(localStorage.getItem(TOKEN_NAME)).token;

	return dispatch => {
		return fetch(`${API_URL}/Organization`, {
			body: JSON.stringify(organization),
			cache: 'no-cache',
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json-patch+json',
			},
			method: 'POST',
			credentials: 'same-origin',
			redirect: 'follow',
			referrer: 'no-referrer',
		})
			.then(response => response.json())
			.then(response => {
				if (response.id) {
					dispatch({
						type: ORGANIZATION_CREATE,
						payload: response,
					});
					history.push('/forening');
				} else {
					dispatch(
						organizationsError(
							response.message || `Couldn't create organization.`
						)
					);
				}
			})
			.catch(() => {
				dispatch(organizationsError(`Couldn't create organization.`));
			});
	};
};

/**
 * Action for updating a organization
 * @param {Object} organization - The organization to update
 * @param {Object} history
 */

export const updateOrganization = (organization, history) => {
	const token = JSON.parse(localStorage.getItem(TOKEN_NAME)).token;

	return dispatch => {
		return fetch(`${API_URL}/Organization/${organization.id}`, {
			body: JSON.stringify(organization),
			cache: 'no-cache',
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json-patch+json',
			},
			method: 'PATCH',
			credentials: 'same-origin',
			redirect: 'follow',
			referrer: 'no-referrer',
		})
			.then(response => response.json())
			.then(response => {
				if (response.id) {
					dispatch({
						type: ORGANIZATION_UPDATE,
						payload: response,
					});
					history.push('/forening');
				} else {
					dispatch(
						organizationsError(
							`Couldn't update organization with id ${organization.id}.`
						)
					);
				}
			})
			.catch(e => {
				dispatch(
					organizationsError(
						`Couldn't update organization with id ${organization.id}.`
					)
				);
			});
	};
};

/**
 * Action for deleting a organization
 * @param {Number} organizationId - Id of the organization to delete
 */

export const deleteOrganization = organizationId => {
	const token = JSON.parse(localStorage.getItem(TOKEN_NAME)).token;

	return dispatch => {
		return fetch(`${API_URL}/Organization/${organizationId}`, {
			cache: 'no-cache',
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json-patch+json',
			},
			method: 'DELETE',
			credentials: 'same-origin',
			redirect: 'follow',
			referrer: 'no-referrer',
		})
			.then(response => {
				if (response.status === 200) {
					dispatch({
						type: ORGANIZATION_DELETE,
						payload: organizationId,
					});
				} else {
					dispatch(
						organizationsError(
							`Couldn't delete organization with id ${organizationId}.`
						)
					);
				}
			})
			.catch(() => {
				dispatch(
					organizationsError(
						`Couldn't delete organization with id ${organizationId}.`
					)
				);
			});
	};
};

/**
 * Action for dispatching a organizations error
 */
export function organizationsError(error) {
	return {
		type: ORGANIZATION_ERROR,
		payload: error,
	};
}
