/**
 * LoginPage
 * @module pages/LoginPage
 */

import React, { useState } from 'react';
import './LoginPage.scss';
import { Form, Field, Button } from 'components/Form';
import { API_URL, TOKEN_NAME } from 'common/config';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { fetchReports } from 'actions/report.actions';
import { fetchUsers } from 'actions/user.actions';
import { fetchOrganizations } from 'actions/organization.actions';
import { fetchMetadata } from 'actions/metadata.actions';

import StandardLayout from 'layouts/StandardLayout';
import Layout from 'components/Layout';

const LoginPage = ({ location, ...props }) => {
	let { from } = location.state || { from: { pathname: '/' } };

	const [redirectToReferrer, setRedirectToReferrer] = useState(false);
	const [error, setError] = useState(false);
	const [message, setMessage] = useState(
		'Någonting gick fel vid din begäran, försök igen om en stund.'
	);

	const logInUser = values => {
		fetch(`${API_URL}/Auth/Login?type=admin`, {
			body: JSON.stringify(values),
			cache: 'no-cache',
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				'Content-Type': 'application/json-patch+json',
			},
			method: 'POST',
			credentials: 'same-origin',
			redirect: 'follow',
			referrer: 'no-referrer',
		})
			.then(response => response.json())
			.then(response => {
				if (response.token) {
					const token = { token: response.token, expires: response.expire };
					localStorage.setItem(TOKEN_NAME, JSON.stringify(token));

					props.fetchUsers();
					props.fetchMetadata();
					props.fetchReports();
					props.fetchOrganizations();
					setRedirectToReferrer(true);
				} else {
					setMessage(response.message);
					setError(true);
				}
			})
			.catch(error => {
				setError(true);
			});
	};

	if (redirectToReferrer) {
		return <Redirect to={from} />;
	}

	return (
		<StandardLayout location={location}>
			<main id="main-content" className="loginpage">
				<Layout>
					<h1 className="loginpage__heading">Logga in</h1>
					<Form
						onSubmit={logInUser}
						initialValues={{ email: '', password: '' }}
						className="form loginpage__form form--center"
					>
						<div className="loginpage__input form__item--Text">
							<label htmlFor="email" className="is-visuallyhidden">
								Ange användarnamn (e-post)
							</label>
							<Field
								id="email"
								type="text"
								placeholder="Namn"
								name="email"
								required
							/>
						</div>
						<div className="loginpage__input form__item--Text">
							<label htmlFor="password" className="is-visuallyhidden">
								Ange lösenord
							</label>
							<Field
								id="password"
								type="password"
								placeholder="Lösenord"
								autoComplete="password"
								name="password"
								required
							/>
						</div>
						{error && <p className="loginpage__error">{message}</p>}
						<Button
							type="submit"
							className="form__button--Submit loginpage__button"
						>
							Logga in
						</Button>
					</Form>
				</Layout>
			</main>
		</StandardLayout>
	);
};

const mapStateToProps = state => {
	return {
		report: state.report,
		metadata: state.metadata,
		user: state.user,
	};
};

export default connect(
	mapStateToProps,
	{ fetchUsers, fetchReports, fetchMetadata, fetchOrganizations }
)(LoginPage);
